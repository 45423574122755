import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { logGoogleEvent, idioma, langPath } from './analytics';

const VideoHandler = () => {
  gsap.registerPlugin(ScrollTrigger);

  const _getVideoSize = () => {
    let width = window.screen.width;
    if(width >= 1280) return '1920x1080';
    if(width >= 720 && width < 1280) return '1280x720';
    if(width < 720) return '720x404';
  }

  const _getLang = () => {
    let pathname = location.pathname;
    if(pathname.includes("/ca")) return "CA";
    if(pathname.includes("/eu")) return "EU";
    return 'ES';
  }

  const loadPreview = () => {
    console.log('loadPreview');
    let req = new XMLHttpRequest();
    // let lang = _getLang();
    let lang = 'ES';
    let videoSize = _getVideoSize();
    req.open('GET', `${langPath()}src/assets/video/Preview-${lang}-${videoSize}.mp4`, true);
    req.responseType = 'blob';

    req.onload = function() {
      if (this.status === 200) {
        var videoBlob = this.response;
        var vid = URL.createObjectURL(videoBlob);
        let video = document.getElementById("video-preview");
        let modalForm = document.querySelector(".modal-form");
        video.src = vid;
        video.muted = true;
        if(modalForm.style.opacity != "1") video.play();
        video.loop = true;
        setTimeout( () => {
          gsap.to(".video-content",
          {
            duration: 0.5,
            opacity: 1
          });
          gsap.to('header', {
            autoAlpha: 1,
            duration: 0.5
          });
        
          gsap.to('.video-skip', {
            autoAlpha: 1,
            duration: 0.5
          });     

          gsap.to('.video-loader', {
            autoAlpha: 0,
            duration: 0.5
          });     
        },500)
      }
    }
    req.onerror = ()=> { 
      //console.log("ERROR WITH VIDEO"); 
    }
    let letra = req.statusText.length
    req.send()
    //console.log('end load prevew')
  }

  const loadVideo = () => {
    //console.log('loadVideo');
    gsap.to("#video-loader",
    {
      duration: 0.5,
      autoAlpha: 1,
    });
    gsap.to("#video",
    {
      duration: 0.5,
      opacity: 1,
    });
    let req = new XMLHttpRequest();
    let lang = _getLang();
    let videoSize = _getVideoSize();
    req.open('GET', `${langPath()}src/assets/video/${lang}-${videoSize}.mp4`, true);
    req.responseType = 'blob';
    req.onload = function() {
      if (this.status === 200) {
        //console.log("Delayed for 1 second.");
        var videoBlob = this.response;
        var vid = URL.createObjectURL(videoBlob);
        let video = document.getElementById("video");
        video.loop = false;
        video.onended = () => {
          close();
        }
        video.src = vid;
        video.muted = false;
        video.play();
        gsap.to("#video-preview",
        {
          duration: 0.5,
          opacity: 0,
        });
        gsap.to(".video-content",
        {
          duration: 0.5,
          opacity: 1
        });    
        gsap.to("#video-loader",
        {
          duration: 0.5,
          autoAlpha: 0
        });    
      }
    }
    req.onerror = ()=> { 
      //console.log("ERROR WITH VIDEO"); 
    }
    let letra = req.statusText.length
    // //console.log('VIDEO => ',letra);
    req.send();
    gsap.to(".titles-content",{
      y:-50,
      autoAlpha: 0,
      duration: 1
    });     
    gsap.fromTo(".video-mute",{
      y:-50
      },
      {
        y: 0,
        autoAlpha: 1,
        duration: 1,
        onComplete: () => {

        }
    });
  }

  const _show = () => {
    let videoModal = document.querySelector('.video-modal');
    videoModal.classList.add('show');
  }

  const start = () => {
    let video = document.getElementById("video");
    if(video){
      loadPreview();
      _show();
    }
  }


  const toggleHeader = () => {
    gsap.to('#headerlogo',{
      autoAlpha: 1,
      duration: 1,
    });
  }

  const close = () => {
    //console.log('video-close');
    document.body.style.overflow = "unset";
    document.body.style.overflowX="clip";
    gsap.to('.video-modal', {
      autoAlpha: 0,
      duration: .5,
      onStart: () => {
        toggleHeader();
      },
      onComplete: () => {

        showMain();
        logGoogleEvent('interaccion', {'pagina': 'home', 'accion': 'finish video', 'idioma': idioma()});
      }
    });
  }


  const showMain = () => {
    //console.log('showMain');
    document.body.style.overflow = "unset";
    document.body.style.overflowX="clip";
    gsap.to('.main-wrap', {
      autoAlpha: 1,
      duration: 0.5,
    });
    
    gsap.to('header', {
      autoAlpha: 1,
      duration: 0.5
    });

    gsap.to('#index-loader', {
      display: 'none',
      autoAlpha: 0,
      duration: 0.5
    });

    video.pause();
    document.querySelector("#video-modal").remove();
    ScrollTrigger.refresh();
    toggleHeader();
  }


  const startEventClick = () => {
    document.getElementById("video-modal").addEventListener('click', (e) => {
      const target = e.target;
      const video = document.getElementById("video");
      if(target.matches(".video-start")){
        loadVideo();
        logGoogleEvent('interaccion', {'pagina': 'home', 'accion': 'start video', 'idioma': idioma()});
      } 

      if(target.matches(".video-skip")){
        close();
        logGoogleEvent('interaccion', {'pagina': 'home', 'accion': 'skip video', 'idioma': idioma()});
      } 

      if(target.matches(".video-mute")){
        let current = video.muted;
        video.muted = !current;
        document.querySelector(".video-mute").classList.toggle('off', video.muted);
        logGoogleEvent('interaccion', {'pagina': 'home', 'accion': 'mute video', 'idioma': idioma()});
      }
    })
  }

  return {
    start,
    startEventClick,
    close,
    showMain
  }
}

export default VideoHandler;
