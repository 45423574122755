import './../../css/global/global.scss';
import FormHandler from './formHandler';
import VideoHandler from './videoHandler';
import { detectMob } from './ismobile';
import gsap from "gsap";
import { logGoogleEvent, idioma, langPath, langUrl } from './analytics';

export const Global = () => {

  //Detect mobile:
  let ismobile = detectMob();

  const videoHandler = VideoHandler();
  const path = location.pathname;

  //Init form
  const formHandler = FormHandler();

  //Check params.
  const params = new URLSearchParams(location.search);
  if(params.get("ac")){
    if(params.get("ac") == "form") {
      gsap.to('.main-wrap', {
        autoAlpha: 1,
        duration: 0.5,
      });
      formHandler.show();
    }
    if(params.get("ac") == "skip"){
      gsap.to('.main-wrap', {
        autoAlpha: 1,
        duration: 0.5,
      });
      videoHandler.close();
    }else{
      videoHandler.startEventClick();
      videoHandler.start();
    } 
  }
  
  let zigzagPath = "src/assets/image/lottie/AFE_Texto_ZigZag_desktop.json";
  if(idioma() == 'eu') zigzagPath = "src/assets/image/lottie/AFE_Texto_ZigZag_desktop_ESK.json";
  if(idioma() == 'ca') zigzagPath = "src/assets/image/lottie/AFE_Texto_ZigZag_desktop_CAT.json";

  //Charge lotties if not mobile

  lottie.loadAnimation({
    container: document.querySelector("#modal-mark-lottie"),
    renderer: "svg",
    filterSize: {
      width: '100%',
      height: '100%',
    },
    loop: true,
    autoplay: true,
    path: langPath() +  "src/assets/image/lottie/enviar_rayas_azul.json"
  });

  if( window.innerWidth > 950 && !ismobile ){

    lottieOnOver('#home-form-submit', '#modal-send-static', '#modal-send');
    lottieOnOver('#home-form-submit', '#modal-mark-static', '#modal-mark-lottie');
    
  }


  if( window.innerWidth > 780 && !ismobile ){
    // lottie.loadAnimation({
    //   container: document.querySelector("#letterSpacing2"),
    //   renderer: "svg",
    //   loop: true,
    //   autoplay: true,
    //   path: langPath() + zigzagPath
    // });
   

    lottie.loadAnimation({
      container: document.querySelector("#modal-send"),
      renderer: "svg",
      filterSize: {
        width: '100%',
        height: '100%',
      },
      loop: true,
      autoplay: true,
      path: langPath() +  "src/assets/image/lottie/enviar_verde.json"
    });

  
    const lottieArrow = document.querySelectorAll(".lottieArrow");
    lottieArrow.forEach(elemento => {
      lottie.loadAnimation({
        container: elemento,
        renderer: "svg",
      loop: true,
      autoplay: true,
      path: langPath() +  "src/assets/image/lottie/flecha_cta.json"
      });
    });

    lottie.loadAnimation({
      container: document.querySelector("#modal-arrow-lottie"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: langPath() +  "src/assets/image/lottie/flecha_curva_horizontal_verde.json"
    });
    lottieOnOver('#index-modal-form-info-btn', '#modal-arrow-static', '#modal-arrow-lottie');
  
    lottie.loadAnimation({
      container: document.querySelector("#inner-arrow-lottie"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: langPath() +  "src/assets/image/lottie/flecha_curva_horizontal.json"
    });
    lottieOnOver('#index-inner-form-info-btn', '#inner-arrow-static', '#inner-arrow-lottie');

  }

  //Lotties mobile and desktop:

  switch(idioma()){
    case "eu":
    lottie.loadAnimation({
      container: document.querySelector("#logo1lottieheader"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: langPath() + "src/assets/image/lottie/armaduras-1102-EUS.json",
    });
    lottie.loadAnimation({
      container: document.querySelector("#logo2lottieheader"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: langPath() + "src/assets/image/lottie/emocionales-1102-EUS.json",
    });
    break;
  
    case "ca": 
      lottie.loadAnimation({
        container: document.querySelector("#logo1lottieheader"),
        renderer: "svg",
        loop: true,
        autoplay: true,
        path: langPath() + "src/assets/image/lottie/armaduras-1102-CAT.json",
      });
      lottie.loadAnimation({
        container: document.querySelector("#logo2lottieheader"),
        renderer: "svg",
        loop: true,
        autoplay: true,
        path: langPath() + "src/assets/image/lottie/emocionales-1102-CAT.json",
      });
    break;
  
    default:
      lottie.loadAnimation({
        container: document.querySelector("#logo1lottieheader"),
        renderer: "svg",
        loop: true,
        autoplay: true,
        path: langPath() + "src/assets/image/lottie/armaduras-1102.json",
      });
      lottie.loadAnimation({
        container: document.querySelector("#logo2lottieheader"),
        renderer: "svg",
        loop: true,
        autoplay: true,
        path: langPath() + "src/assets/image/lottie/emocionales-1102.json",
      });
  
  }
  

  let ctaLottie = lottie.loadAnimation({
    container: document.querySelector("#cta-lottie"),
    renderer: "svg",
    rendererSettings: {
      filterSize: {
        width: '100%',
        height: '100%',
      }
    },
    loop: true,
    autoplay: true,
    path: langPath() +  "src/assets/image/lottie/oval.json",
  });

  let ctaMarkLottie = lottie.loadAnimation({
    container: document.querySelector("#cta-mark-lottie"),
    renderer: "svg",
    rendererSettings: {
      filterSize: {
        width: '100%',
        height: '100%',
      }
    },
    loop: true,
    autoplay: true,
    path: langPath() +  "src/assets/image/lottie/cta-marks.json",
  });

  


  // ######################### FORM #########################

  //  $(".custom-select2").select2({
  //   containerCssClass: "select2-container",
  //   dropdownCssClass: "select2-dropdown",
  //   selectionCssClass: "select2-selection",
  //   minimumResultsForSearch: Infinity
  // });

  $(document).ready(function() {
    // Inicializar el Select2
    $(".custom-select2").select2({
      containerCssClass: "select2-container",
      dropdownCssClass: "select2-dropdown",
      selectionCssClass: "select2-selection",
      minimumResultsForSearch: Infinity
    });

    if($('.custom-select2').closest('.inner-form') != null){
      console.log("a");
      let image = $(this).parent().find('.select2-selection__rendered');
      image.addClass('white');
    }
  
    // Agregar evento select2:open
    $('.custom-select2').on('select2:open', function(e) {
      // Obtener el elemento de la imagen
      console.log('open');
      let image = $(this).parent().find('.select2-selection__rendered');

      // Rotar la imagen agregando la clase .rotate
      image.addClass('rotate');

    });

    // Agregar evento select2:close
    $('.custom-select2').on('select2:close', function(e) {
      // Obtener el elemento de la imagen
      let image = $(this).parent().find('.select2-selection__rendered');

      // Rotar la imagen de regreso a su posición original eliminando la clase .rotate
      image.removeClass('rotate');
    });
});

  

  const getFormData = (form) => {
    let fields = ["nm", "lnm", "tit", "exp", "pr", "em", "tlf", "root"];
    let formData = {};
    fields.forEach(field => {
      formData[field] = form[field].value;
    })
    return formData;
  }

  const sendFormData = async(form) => {
    logGoogleEvent('interaccion', {'pagina': 'home', 'accion': 'click', 'btn': 'valid-form', 'idioma': idioma()});
    const response = await fetch( "https://omni.agtz.idbl.es/api/omni/grvtfrms", {
      method: 'POST', 
      body: JSON.stringify(form),
      headers: {
        "Content-type": "application/json;charset=UTF-8"
      }
    }).then(resp => {
      console.log("STATUS: ", resp.status);
      if(resp.status == 200){
        console.log("FORM OK-----2");
        logGoogleEvent('form-submitted');
        logGoogleEvent('interaccion', {'pagina': 'home', 'accion': 'ok-form', 'idioma': idioma()});
        try {
          fbq('track', 'Lead');
          window.lintrk('track', { conversion_id: 12549561 });
        } catch (error) {
          
        }
      }
      //TODO: Revisar el status de la peticion y disparar evento.
    }).catch((error) => {
      console.log("Error: ", error);
      logGoogleEvent('interaccion', {'pagina': 'home', 'accion': 'click', 'btn': 'error-form', 'idioma': idioma()});
      
      //TODO: disparar evento de error.
    });
    return response;
  }

  //listeners

  document.addEventListener('click', (e) => {
    const target = e.target;

    //form modal
    if(target.matches("#home-form-submit")){ 
      logGoogleEvent('interaccion', {'pagina': 'home', 'accion': 'click', 'btn': 'enviar-form', 'idioma': idioma()});
      
      let formElement = target.closest('form');
      let form = getFormData(formElement);
      e.preventDefault();
      if(formHandler.validate(form, formElement)){
        sendFormData(form).then(resp => { 
          //TODO: Mostrar popup solo si el status es 200
          showThanksModal();
        });
      }else{
        return false;
      };
    }

    ////console.log(" click on ",target);
    if(target.matches("#linkHomeRedesAfe") || target.matches("#linkHomeRedesAfeFooter")){
      ////console.log(" click on #linkHomeRedesAfe");
      window.open('https://www.redesafe.org/', '_blank');
    }

    //form home
    if(target.matches("#menu-form-submit")){ 
      logGoogleEvent('interaccion', {'pagina': 'home', 'accion': 'click', 'btn': 'enviar-form', 'idioma': idioma()});
      e.preventDefault();
      let formElement = target.closest('form');
      let form = getFormData(formElement);
      if(formHandler.validate(form, formElement)){
        e.preventDefault();
        sendFormData(form).then(resp => {
          //TODO: Mostrar popup solo si el status es 200
           console.log ('ENVIADO FORM 3');
          showThanksMessage();
        });
      };
    }

    if(target.matches("#que-es-afe")){
      logGoogleEvent('interaccion', {'accion': 'click', 'btn': 'footer-que-es-afe', 'idioma': idioma()});
      gsap.to(document.body, {
        opacity: 0,
        duration: .2, 
        onComplete: function() {
          let currentUrl = window.location.hostname;
          let url= `https://redesafe.org/armaduras-emocionales/${langUrl()}que-es-afe/`;
        
          if(currentUrl.includes("localhost")){
            url = `http://localhost:3300/${langUrl()}que-es-afe/`;
          }
          console.log(url);
          window.location.href = url;
        }
      });  
    }
  
    if(target.matches("#dudas")){
      logGoogleEvent('interaccion', {'accion': 'click', 'btn': 'footer-dudas', 'idioma': idioma()});
      switch(idioma()){
        default:
          window.open('https://www.redesafe.org/', '_blank');
          break;
        case 'eu':
          window.open('https://www.redesafe.org/eus/ ', '_blank');
          break;
        case 'ca':
          window.open('https://www.redesafe.org/cat/ ', '_blank');
          break;
      }
    }

    if(target.matches("#legal")){
      logGoogleEvent('interaccion', {'accion': 'click', 'btn': 'footer-aviso-legal', 'idioma': idioma()});
      switch(idioma()){
        default:
          window.open('https://www.redesafe.org/aviso-legal/', '_blank');
          break;
        case 'eu':
          window.open('https://www.redesafe.org/eus/lege-oharra/ ', '_blank');
          break;
        case 'ca':
          window.open('https://www.redesafe.org/cat/avis-legal/ ', '_blank');
          break;
      }
    }

    if(target.matches("#cookies")){
      logGoogleEvent('interaccion', {'accion': 'click', 'btn': 'footer-cookies', 'idioma': idioma()});
      switch(idioma()){
        default:
          window.open('https://www.redesafe.org/politica-de-cookies/', '_blank');
          break;
        case 'eu':
          window.open('https://www.redesafe.org/eus/cookieen-politika/ ', '_blank');
          break;
        case 'ca':
          window.open('https://www.redesafe.org/cat/politica-de-cookies-2/ ', '_blank');
          break;
      }
    }

    if(target.matches("#privacity")){
      logGoogleEvent('interaccion', {'accion': 'click', 'btn': 'footer-politica-privacidad', 'idioma': idioma()});

      console.log(idioma());
      switch(idioma()){
        default:
          window.open('https://www.redesafe.org/politica-de-privacidad/', '_blank');
          break;
        case 'eu':
          window.open('https://www.redesafe.org/eus/pribatutasun-politika/', '_blank');
          break;
        case 'ca':
          window.open('https://www.redesafe.org/cat/politica-de-privacitat/ ', '_blank');
          break;
      }
    }

    //El de cta en está en formHandler.js

    if(target.matches(".mailto")){
      logGoogleEvent('interaccion', {'accion': 'click', 'btn': 'footer-contacta', 'idioma': idioma()});
      window.open('mailto:info@redesafe.org', '_blank');
    }


    
  })

  const showThanksModal = () => {
    gsap.to('.form-content', {
      opacity: 0,
      duration: .5, 
      onComplete: function() {
        document.querySelector('.form-content').classList.add("hidden");
        document.querySelector('#thank-you-modal-wrap').classList.remove("hidden");
        gsap.to('#thank-you-modal-wrap', {
          opacity: 1,
          duration: .5
        });
      }
    });
  }

  const showThanksMessage = () => {
    gsap.to('.main-modal-wrap', {
      opacity: 0,
      duration: .5, 
      onComplete: function() {
        document.querySelector('.main-modal-wrap').classList.add("hidden");
        document.querySelector('#thank-you-wrap').classList.remove("hidden");
        gsap.to('#thank-you-wrap', {
          opacity: 1,
          duration: .5
        });
      }
    });
  }

  gsap.to('#loader-img', { rotation: 360, repeat: -1 });


  // ######################### END FORM #########################


  // ######################### HEADER #########################

  if(window.innerWidth > 950 && !ismobile){
    lottieOnOver('#cta', '#cta-static', '#cta-lottie');


    // lottieOnOver('#ctaArrowWrap1', '#staticArrow1', '#lottieArrow1');
    // lottieOnOver('#ctaArrowWrap2', '#staticArrow2', '#lottieArrow2');
    // lottieOnOver('#ctaArrowWrap3', '#staticArrow3', '#lottieArrow3');
    // lottieOnOver('#ctaArrowWrap4', '#staticArrow4', '#lottieArrow4');
    // lottieOnOver('#ctaArrowWrap5', '#staticArrow5', '#lottieArrow5');
    // lottieOnOver('#ctaArrowWrap6', '#staticArrow6', '#lottieArrow6');
    // lottieOnOver('#ctaArrowWrap7', '#staticArrow7', '#lottieArrow7');
    // lottieOnOver('#ctaArrowWrap8', '#staticArrow8', '#lottieArrow8');
    // lottieOnOver('#ctaArrowWrap9', '#staticArrow9', '#lottieArrow9');
    // lottieOnOver('#cta', '#cta-mark-static', '#cta-mark-lottie');


  }


  // ######################### END HEADER #########################


  //Lang 

  let langSelector = document.querySelector(".lang-selector");
  let items = langSelector.querySelectorAll('.lang-item:not(.active)');
  let arrow = langSelector.querySelector('.lang-arrow');
  // if any are found, create the animation and mouseover/mouseout listeners
  if (items.length > 0) {
    let animation = gsap.fromTo(items, {opacity: 0, x: 50}, {
        opacity: 1,
        display: "block",
        x: 0,
        stagger: 0.08,
        paused: true
      })

    let selector = gsap.fromTo(arrow, {opacity: 0}, {
      opacity: 1
    });
    langSelector.addEventListener("mouseover", () => {
      animation.play();
      selector.reverse()
    });
    langSelector.addEventListener("mouseout", () => {
      animation.reverse();
      selector.play()
    });
  }

  
  // ######################### FOOTER #########################

  if(!ismobile){
    textOnOver('#que-es-afe', '#que-es-afe');
    textOnOver('#acoge', '#acoge');
    textOnOver('#dudas', '#dudas');
    textOnOver('#legal', '#legal');
    textOnOver('#cookies', '#cookies');
    textOnOver('#privacity', '#privacity');

    const acogerBtns = document.querySelectorAll('.acoger-btn > span');

    acogerBtns.forEach(function(span) {
      const parent = span.parentNode;
      textOnOver(span, parent);
    });

  }
  
  

  
  // ######################### END FOOTER #########################

}

export const goQueEsAfe = () => {
  gsap.to(document.body, {
    opacity: 0,
    duration: .5, 
    onComplete: function() {
      var currentUrl = window.location.hostname;
      let url= "https://redesafe.org/armaduras-emocionales/que-es-afe/";

      if(currentUrl.includes("localhost")){
        url = "http://localhost:3300/que-es-afe/"
      }
      window.location.href = url;
    }
  }); 
}


export const lottieOnOver = (over, base, lottie) => {
  let overElement;
  if (over instanceof HTMLElement) {
    overElement = over;
  } else {
    overElement = document.querySelector(over);
  }

  let baseElement;
  if (base instanceof HTMLElement) {
    baseElement = base;
  } else {
    baseElement = document.querySelector(base);
  }

  let lottieElement;
  if (lottie instanceof HTMLElement) {
    lottieElement = lottie;
  } else {
    lottieElement = document.querySelector(lottie);
  }

  if(lottieElement == null || baseElement == null) return;

  try {
    overElement.addEventListener("mouseover", function() {
      baseElement.classList.add("hidden");
      lottieElement.classList.remove("hidden");
    });
  
    overElement.addEventListener("mouseout", function() {
      baseElement.classList.remove("hidden");
      lottieElement.classList.add("hidden");
    });
  } catch (error) {
    // //console.log(error)
    // //console.log('over', over)
    // //console.log('base', base)
    // //console.log('lottie', lottie)
  }
  
}

export const textOnOver = (element, pointer) => {
  let item;
  if (element instanceof HTMLElement) {
    item = element;
  } else {
    item = document.querySelector(element);
  }

  let pointerEl;
  if (pointer instanceof HTMLElement) {
    pointerEl = pointer;
  } else {
    pointerEl = document.querySelector(pointer);
  }

  if(item == null || pointerEl == null) return;

  let text = item.innerText;
  let arrSplitText = text.split('');
  arrSplitText.forEach(function(t, i){
    if (arrSplitText[i] === ' ') {
      arrSplitText[i] = "<span>&nbsp;</span>";
    } else {
      arrSplitText[i] = "<span>" + arrSplitText[i] + "</span>";
    }
    item.innerHTML = arrSplitText.join('');
  });

  let myAnimation = null; // Creamos una variable global para guardar la instancia de la animación

  let animation = () => {
    let tl = gsap.timeline();

    tl.fromTo(`${element instanceof HTMLElement ? element.tagName.toLowerCase() : element} span`,{
      display: 'unset', 
      y: 0, 
      rotate: 0 
    }, {
      display: 'inline-block',
      repeat: -1,
      duration: 0.3,
      y: 'random(3, -3)',
      rotate: 'random(4, -4)',
      // scale: 'random(0.8, 1.1)',
      ease: "steps(2)", // stop motion happpen
      repeatRefresh: true,
      // stagger: 0.1
    });

    return tl;
  };

  pointerEl.addEventListener("mouseover", function() {
    myAnimation = animation(); // Guardamos la instancia de la animación en la variable global
    myAnimation.play();
  });

  pointerEl.addEventListener("mouseout", function() {
    myAnimation.seek(0);
    myAnimation.pause();
    // myAnimation.pause(); // Hacemos referencia a la misma instancia de la animación para detenerla
  });
};

// gsap.to('#headerlogo',{
//   autoAlpha: 1,
//   duration: 1,
// });


