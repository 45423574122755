
export const logGoogleEvent  = (event_name,event_data) => {
  try {
    gtag('event', event_name,event_data);
  } catch (error) {
    //console.log('err analytics');
  }
}

export const idioma = () =>{
  let path = location.pathname;
  let lang = "es";

  if(path && path.includes('/cat')) lang = "ca";
  if(path && path.includes('/eus')) lang = "eu";

  return lang;
}

export const langPath = () =>{
  let language = idioma();
  let lang_path = "./"; 
  if(language != 'es') lang_path = "../";
  if(location.pathname.includes('que-es-afe')) lang_path = lang_path + "../";
  return lang_path;
}

export const langUrl = () =>{
  let path = location.pathname;
  let lang = "";

  if(path && path.includes('cat')) lang = "cat/";
  if(path && path.includes('eus')) lang = "eus/";

  console.log(lang);
  return lang;
}

