import gsap from "gsap";
import { logGoogleEvent, idioma } from './analytics';

let prevScrollStatus;
const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const TLF_REGEX = /^[+]?[0-9]{1,3}[ -]?(?:\d[ -]?){6,10}\d$/;

const FormHandler = () => {
  const show = () => {
    gsap.to('.modal-form', {
      display: 'flex',
      autoAlpha: 1,
      duration: 0.5,
      OnComplete: () => {
        prevScrollStatus = document.body.style.overflowY;
        document.body.style.overflowY="hidden";
      }
    });;
  }

  const close = () => {
    const video = document.getElementById("video");
    gsap.to('.modal-form', {
      display: 'none',
      autoAlpha: 0,
      duration: 0.5,
      OnComplete: () => {
        document.body.style.overflowY=prevScrollStatus;
        if(video) video.play();
      }
    });
  }

  document.addEventListener('click', (e) => {
    const target = e.target;
    if(target.matches(".acoger-btn-prota")){ //Niño prota
      console.log('acoger-btn-prota');
      logGoogleEvent('interaccion', {'pagina': 'home', 'accion': 'click', 'btn': 'acoger-protagonista', 'idioma': idioma()});
      show();
    } 

    if(target.matches(".acoger-btn-nino")){ //Niños
      let kid = document.querySelector('#info-image').getAttribute('kid');
      console.log('acoger-btn-nino', document.querySelector('#info-image').getAttribute('kid'));
      switch (kid) {
        case '1':
          logGoogleEvent('interaccion', {'pagina': 'home', 'accion': 'click', 'btn': 'acoger-metal', 'idioma': idioma()});
          break;
        case '2':
          logGoogleEvent('interaccion', {'pagina': 'home', 'accion': 'click', 'btn': 'acoger-samurai', 'idioma': idioma()});
          break;
        case '3':
          logGoogleEvent('interaccion', {'pagina': 'home', 'accion': 'click', 'btn': 'acoger-libros', 'idioma': idioma()});
          break;
        case '4':
          logGoogleEvent('interaccion', {'pagina': 'home', 'accion': 'click', 'btn': 'acoger-peluches', 'idioma': idioma()});
          break;
        case '5':
          logGoogleEvent('interaccion', {'pagina': 'home', 'accion': 'click', 'btn': 'acoger-chapas', 'idioma': idioma()});
          break;
      }
      show();
    }

    if(target.matches(".cta")){ //BTN principal
      logGoogleEvent('interaccion', {'pagina': 'home', 'accion': 'click', 'btn': 'acoger-principal', 'idioma': idioma()});
      const video = document.getElementById("video");
      if(video) video.pause();
      show();
    } 

    if(target.matches(".ctaFooter")){
      logGoogleEvent('interaccion', {
        'accion': 'click', 
        'btn': 'footer-acoge', 
        'idioma': idioma()
      });
      const video = document.getElementById("video");
      if(video) video.pause();
      show();
    }
    

    if(target.matches(".modal-close")) close();
  })

  var checkboxes = document.querySelectorAll("input[type='checkbox']");
  checkboxes.forEach(element => {
    element.addEventListener("change", function() {
      if(element.checked) {
        element.setAttribute("checked", "");
      } else {
        element.removeAttribute("checked");
      }
    });
  });

  const validate = (form, formElement) => {
    _resetFields();

    let isValid = true;

    if(form.nm == ""){
      formElement.querySelector('input[name="nm"]').parentNode.classList.add("color-orange");
      formElement.querySelector('input[name="nm"]').parentNode.querySelector(".error").classList.remove("hidden");
      isValid = false;
    }

    if(form.lnm == ""){
      formElement.querySelector('input[name="lnm"]').parentNode.classList.add("color-orange");
      formElement.querySelector('input[name="lnm"]').parentNode.querySelector(".error").classList.remove("hidden");
      isValid = false;
    }

    if(form.tlf == ""){
      formElement.querySelector('input[name="tlf"]').parentNode.classList.add("color-orange");
      formElement.querySelector('input[name="tlf"]').parentNode.querySelector(".error").classList.remove("hidden");
      let error_text = "Campo obligatorio.";
      switch(idioma()){
        case 'eu':
          error_text = "Derrigorrezko eremua.";
          break;
        case 'ca':
          error_text = "Camp obligatori.";
          break;
        default:
          error_text = "Campo obligatorio.";
          break;
      }
      formElement.querySelector('input[name="tlf"]').parentNode.querySelector(".error").textContent = error_text;
      isValid = false;
    }

    if(form.tlf != "" && !TLF_REGEX.test(form.tlf)){
      formElement.querySelector('input[name="tlf"]').parentNode.classList.add("color-orange");
      formElement.querySelector('input[name="tlf"]').parentNode.querySelector(".error").classList.remove("hidden");
      let error_text = "Formato incorrecto.";
      switch(idioma()){
        case 'eu':
          error_text = "Formatu okerra.";
          break;
        case 'ca':
          error_text = "Format incorrecte.";
          break;
        default:
          error_text = "Formato incorrecto.";
          break;
      }
      formElement.querySelector('input[name="tlf"]').parentNode.querySelector(".error").textContent = error_text;
      
      isValid = false;
    }

    if(form.em == ""){
      formElement.querySelector('input[name="em"]').parentNode.classList.add("color-orange");
      formElement.querySelector('input[name="em"]').parentNode.querySelector(".error").classList.remove("hidden");
      let error_text = "Campo obligatorio.";
      switch(idioma()){
        case 'eu':
          error_text = "Derrigorrezko eremua.";
          break;
        case 'ca':
          error_text = "Camp obligatori.";
          break;
        default:
          error_text = "Campo obligatorio.";
          break;
      }
      formElement.querySelector('input[name="em"]').parentNode.querySelector(".error").textContent = error_text;
      isValid = false;
    }

    if(form.em != "" && !EMAIL_REGEX.test(form.em)){
      formElement.querySelector('input[name="em"]').parentNode.classList.add("color-orange");
      formElement.querySelector('input[name="em"]').parentNode.querySelector(".error").classList.remove("hidden");
      let error_text = "Formato incorrecto.";
      switch(idioma()){
        case 'eu':
          error_text = "Formatu okerra.";
          break;
        case 'ca':
          error_text = "Format incorrecte.";
          break;
        default:
          error_text = "Formato incorrecto.";
          break;
      }
      formElement.querySelector('input[name="em"]').parentNode.querySelector(".error").textContent = error_text;
      isValid = false;
    }
    
    if(form.tit == ""){
      formElement.querySelector('input[name="tit"]').parentNode.classList.add("color-orange");
      formElement.querySelector('input[name="tit"]').parentNode.querySelector(".error").classList.remove("hidden");
      isValid = false;
    }

    if(form.exp == ""){
      formElement.querySelector('input[name="exp"]').parentNode.classList.add("color-orange");
      formElement.querySelector('input[name="exp"]').parentNode.querySelector(".error").classList.remove("hidden");
      isValid = false;
    }
    
    let select = $(formElement.querySelector(".custom-select2"))
    if(select.val() == "" || select.val() == null){
      formElement.querySelector(".custom-select2").parentNode.parentNode.classList.add("color-orange");
      formElement.querySelector(".custom-select2").parentNode.parentNode.querySelector('.error').classList.remove('hidden')
      isValid = false;
    }

    let checkbox = formElement.querySelector(".checkbox");
    if (!checkbox.checked) {
      checkbox.parentNode.querySelector('.error').parentNode.classList.add("color-orange");
      checkbox.parentNode.querySelector('.error').classList.remove('hidden');
      isValid = false;
    }

    return isValid;
  };

  let _resetFields = () => {
    let inputs = document.querySelectorAll('input');
    inputs.forEach(element => {
      element.parentNode.classList.remove("color-orange");
      if(element.parentNode.querySelector(".error"))
      element.parentNode.querySelector(".error").classList.add("hidden");
    });

    let selects = document.querySelectorAll(".custom-select2");
    selects.forEach(element => {
      element.parentNode.parentNode.classList.remove("color-orange");
      if(element.parentNode.parentNode.querySelector(".error"))
      element.parentNode.parentNode.querySelector(".error").classList.add("hidden");
    });
  }

  return {
    show,
    close,
    validate,
  }
}

export default FormHandler;

