export const detectMob = () => {
  const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
  ];
  
  return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
  });  
}

export const detectIOS = () => {
  const toMatch = [
      /iPhone/i,
      /iPad/i,
      /iPod/i
  ];
  
  return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
  });  
}

export const detectSafari = () => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}
